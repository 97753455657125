<template>
<v-card class="video-card" @click="goTo()">
  <v-img contain v-if="vimeoData" :src="vimeoData.thumbnail_url"></v-img>

  <v-card-actions v-if="vimeoData">
    <p class="starling-video-card-title mb-0">
      <span>{{video.title || vimeoData.title}}</span>
      <small class="StarlingAlmostBlack--text body-1 ml-3">({{formatDuration(vimeoData.duration)}})</small></p>
  </v-card-actions>
</v-card>
</template>

<script>
function padLeft(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

export default {
  name: 'videoCard',
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vimeoData: null,
    };
  },
  watch: {
    video: {
      immediate: true,
      handler(newVal, oldVal) {
        this.thumbnailUrl = null;
        this.$http.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${this.video.vimeoId}`)
          .then(res => {
            this.vimeoData = res.data;
          });
      },
    },
  },
  methods: {
    goTo() {
      this.$router.push({ name: 'video-view', params: { id: this.video.id } });
    },
    formatDuration(time) {
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      return padLeft(minutes, '0', 2) + ':' + padLeft(seconds, '0', 2);
    },
  },
};
</script>

<style scoped>
.video-card {
  border-radius: 15px;
  cursor: pointer;
}

.starling-video-card-title {
  line-height: 29px;
  color: var(--StarlingPrimary1);
  font-size: 22px;

  @include respond(small_only) {
    line-height: 28px;
    font-size: 20px;
  }

  @include respond(medium_only) {
    line-height: 28px;
    font-size: 21px;
  }
}
</style>
