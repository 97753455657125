var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "video-card",
      on: {
        click: function($event) {
          return _vm.goTo()
        }
      }
    },
    [
      _vm.vimeoData
        ? _c("v-img", {
            attrs: { contain: "", src: _vm.vimeoData.thumbnail_url }
          })
        : _vm._e(),
      _vm.vimeoData
        ? _c("v-card-actions", [
            _c("p", { staticClass: "starling-video-card-title mb-0" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.video.title || _vm.vimeoData.title))
              ]),
              _c(
                "small",
                { staticClass: "StarlingAlmostBlack--text body-1 ml-3" },
                [
                  _vm._v(
                    "(" +
                      _vm._s(_vm.formatDuration(_vm.vimeoData.duration)) +
                      ")"
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }