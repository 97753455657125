var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadingView
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            {
              class: {
                "pa-1": _vm.$vuetify.breakpoint.xsOnly,
                "pa-2": _vm.$vuetify.breakpoint.smOnly,
                "pa-3": _vm.$vuetify.breakpoint.mdOnly,
                "pa-4": _vm.$vuetify.breakpoint.lgOnly,
                "pa-5": _vm.$vuetify.breakpoint.xlOnly
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm10: "", lg8: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "video-card",
                      attrs: {
                        flat: "",
                        elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 3
                      }
                    },
                    [
                      _vm.video && _vm.vimeoData
                        ? _c(
                            "v-container",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "mt-3 mb-3",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm10: "", lg8: "" } },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "starling-h3 StarlingAlmostBlack--text mb-2 text-center text-wrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.video.title ||
                                                _vm.vimeoData.title
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "starling-body text-wrap"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.video.description ||
                                                _vm.vimeoData.description
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm10: "", lg8: "" } },
                                    [
                                      _c("vimeo-video", {
                                        attrs: {
                                          videoId: _vm.video.vimeoId,
                                          identifier: _vm.video.identifier,
                                          transcript: _vm.video.transcript
                                        },
                                        on: { videoEvent: _vm.onVideoEvent }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }