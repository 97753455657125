var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadingView
    ? _c(
        "v-layout",
        {
          staticClass: "categories-card",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("h1", { staticClass: "starling-h1" }, [
              _vm._v(_vm._s(_vm.$t("app.content.title")))
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "", "grid-list-xl": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    _vm._l(_vm.categories, function(category, index) {
                      return _c(
                        "v-flex",
                        { key: index, attrs: { xs6: "", md4: "", lg3: "" } },
                        [
                          _c("category-card", { attrs: { category: category } })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }