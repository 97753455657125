<template>
<v-layout row wrap pt-3 gap-xs-3 class="categories-card" v-if="!loadingView">
  <v-flex xs12>
    <h1 class="starling-h1">{{$t('app.content.title')}}</h1>
  </v-flex>

  <v-flex xs12>
    <v-container fluid pa-0 grid-list-xl>
      <v-layout row wrap>
        <v-flex xs6 md4 lg3 v-for="(category, index) in categories" :key="index">
          <category-card :category="category"></category-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</v-layout>
</template>

<script>
import CategoryCard from './components/category-card';

function chunk(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }

  return chunks;
}

export default {
  name: 'categoriesView',
  components: {
    CategoryCard,
  },
  props: {
    categoriesResource: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      categories: [],
      chunks: [],
    };
  },
  computed: {
    loadingView() {
      return this.$store.state.loadingView;
    },
  },
  mounted() {
    this.$store.commit('setLoadingView', true);
    this.categoriesResource.then(categories => {
      this.$log.debug('Received categories', categories);
      this.categories = categories;
      this.chunks = chunk(this.categories, 2);
      this.$store.commit('setLoadingView', false);
    });
  },
};
</script>

<style scoped>
.v-card.categories-card {
  border-radius: 15px;
}
</style>
