<template>
<v-card class="category-card" @click="goToCategory">
  <v-img :aspect-ratio="1" cover :src="category.background? require('@/assets/svg/video-card/'+category.background) : ''">
    <v-card-title>
      <p class="starling-category-card-title">{{category.title}}</p>
    </v-card-title>

    <v-card-title style="position:absolute; bottom: 0;" class="text-center">
      <v-chip style="background-color: rgba(255, 255, 255, 0.4);" light small>
        <h2 class="caption text-center black--text">{{$t('app.content.category_card.count', {count:category.count})}}</h2>
      </v-chip>
    </v-card-title>
  </v-img>
</v-card>
</template>

<script>
export default {
  name: 'category-card',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToCategory() {
      this.$router.push({ name: 'videos-categories', params: { id: this.category.id } });
    },
  },
};
</script>

<style scoped>
.category-card {
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  cursor: pointer;
}

.starling-category-card-title {
  line-height: 28px;
  color: var(--StarlingPrimary1);
  font-size: 22px;

  @include respond(small_only) {
    line-height: 28px;
    font-size: 20px;
  }

  @include respond(medium_only) {
    line-height: 28px;
    font-size: 21px;
  }
}
</style>
