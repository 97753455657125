var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loadingView && _vm.category
    ? _c(
        "v-layout",
        {
          staticClass: "category-card",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "", "text-center": "" } }, [
            _c("h3", { staticClass: "starling-h3 StarlingAlmostBlack--text" }, [
              _vm._v("\n      " + _vm._s(_vm.category.title) + "\n    ")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-body" }, [
              _vm._v(_vm._s(_vm.category.description))
            ])
          ]),
          _vm.videos
            ? _vm._l(_vm.videos, function(video, index) {
                return _c(
                  "v-flex",
                  { key: index, attrs: { xs12: "" } },
                  [
                    _c("video-thumbnail", {
                      key: index,
                      attrs: { video: video }
                    })
                  ],
                  1
                )
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }