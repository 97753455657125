<template>
<v-layout row wrap pt-3 gap-xs-3 class="category-card" v-if="!loadingView && category">
  <v-flex xs12 text-center>
    <h3 class="starling-h3 StarlingAlmostBlack--text">
      {{category.title}}
    </h3>
  </v-flex>

  <v-flex xs12>
    <p class="starling-body">{{category.description}}</p>
  </v-flex>

  <template v-if="videos">
    <v-flex xs12 v-for="(video, index) in videos" :key="index">
      <video-thumbnail :video="video" :key="index"></video-thumbnail>
    </v-flex>
  </template>
</v-layout>
</template>

<script>
import VideoThumbnail from './components/video-card';

export default {
  name: 'videosView',
  components: {
    VideoThumbnail,
  },
  props: {
    categoryResource: {
      type: Promise,
      required: true,
    },
    categoryVideosResource: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      category: null,
      videos: null,
    };
  },
  computed: {
    loadingView() {
      return this.$store.state.loadingView;
    },
  },
  mounted() {
    this.$store.commit('setLoadingView', true);
    Promise.all([
      this.categoryResource,
      this.categoryVideosResource,
    ]).then(arr => {
      this.category = arr[0];
      this.videos = arr[1];
    }).finally(() => {
      this.$store.commit('setLoadingView', false);
    });
  },
};
</script>

<style scoped>
.v-card.category-card {
  border-radius: 15px;
}
</style>
