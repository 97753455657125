var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "category-card", on: { click: _vm.goToCategory } },
    [
      _c(
        "v-img",
        {
          attrs: {
            "aspect-ratio": 1,
            cover: "",
            src: _vm.category.background
              ? require("@/assets/svg/video-card/" + _vm.category.background)
              : ""
          }
        },
        [
          _c("v-card-title", [
            _c("p", { staticClass: "starling-category-card-title" }, [
              _vm._v(_vm._s(_vm.category.title))
            ])
          ]),
          _c(
            "v-card-title",
            {
              staticClass: "text-center",
              staticStyle: { position: "absolute", bottom: "0" }
            },
            [
              _c(
                "v-chip",
                {
                  staticStyle: {
                    "background-color": "rgba(255, 255, 255, 0.4)"
                  },
                  attrs: { light: "", small: "" }
                },
                [
                  _c("h2", { staticClass: "caption text-center black--text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("app.content.category_card.count", {
                          count: _vm.category.count
                        })
                      )
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }