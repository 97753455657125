<template>
<v-container fluid v-if="!loadingView">
  <v-layout :class="{'pa-1':$vuetify.breakpoint.xsOnly,
                              'pa-2':$vuetify.breakpoint.smOnly,
                              'pa-3':$vuetify.breakpoint.mdOnly,
                              'pa-4':$vuetify.breakpoint.lgOnly,
                              'pa-5':$vuetify.breakpoint.xlOnly}">
    <v-flex xs12 sm10 lg8>
      <v-card flat :elevation="$vuetify.breakpoint.xsOnly?0:3" class="video-card">
        <v-container v-if="video && vimeoData">
          <v-layout row wrap class="mt-3 mb-3">
            <v-flex xs12 sm10 lg8>
              <h3 class="starling-h3 StarlingAlmostBlack--text mb-2 text-center text-wrap">{{video.title || vimeoData.title}}</h3>
              <p class="starling-body text-wrap">{{video.description || vimeoData.description}}</p>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm10 lg8>
              <vimeo-video
                      :videoId="video.vimeoId"
                      :identifier="video.identifier"
                      :transcript="video.transcript"
                      @videoEvent="onVideoEvent"/>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import vimeoVideo from '@/views/components/utilities/vimeo-video.vue';

export default {
  name: 'videoView',
  components: {
    vimeoVideo,
  },
  props: {
    videoResource: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      video: null,
      vimeoData: null,
    };
  },
  computed: {
    loadingView() {
      return this.$store.state.loadingView;
    },
  },
  mounted() {
    this.$store.commit('setLoadingView', true);
    this.videoResource.then(video => {
      this.video = video;
      this.$http.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${this.video.vimeoId}`)
        .then(res => {
          this.vimeoData = res.data;
        });
    }).finally(() => {
      this.$store.commit('setLoadingView', false);
    });
  },
  methods: {
    onVideoEvent(event) {
      this.$store.dispatch('therapy/watchVideoEvent', { videoId: this.video.vimeoId, status: event });
    },
  },
};
</script>

<style scoped>
.v-card.video-card {
  border-radius: 15px;
}
</style>
